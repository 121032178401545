import React,{useEffect, useState} from 'react'
import LandingBg from '../../assets/images/landing.png'
import AutoComplete from '../../components/AutoCompleteSelect'
import Select from '../../components/Select'
import  Button  from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom';
import Search from '../../components/Search'
import { URL } from '../../app/base_url';
import _ from 'lodash'
import { useParams,useNavigate } from 'react-router-dom'
export default function Result() {
    const [loading, setloading] = useState(false)
    const [loading_search, setloading_search] = useState(false)
    const [detail, setdetail] = useState(null)
    const [modules, setmodules] = useState([])
    const [list_jabatan, setlist_jabatan] = useState([])
    const [search, setsearch] = useState('')
    const [search_module, setsearch_module] = useState('')
    const [choosen_jabatan, setchoosen_jabatan] = useState(null)
    const [type_competence_list, settype_competence_list] = useState([])
    const [choosen_type_compentence, setchoosen_type_compentence] = useState(null)
    const [level_competence_list, setlevel_competence_list] = useState([])
    const [choosen_level_compentence, setchoosen_level_compentence] = useState(null)
    let params = useParams();
    let navigate = useNavigate();
    useEffect(() => {
        setloading(true)
        fetch(URL+'/hcdp/listpositions')
            .then((res)=>res.json())
            .then((data)=>{
                let new_jabatan=[]
                data.map((d)=>new_jabatan.push({...d,group:"Ketik dan pilih nama jabatan yang ingin ditinjau"}))
                setlist_jabatan(new_jabatan)
            })
        fetch(URL+'/hcdp/detailpositions/'+params.jabatan)
            .then((res)=>res.json())
            .then((data)=>{
                let new_type=_.chain(data.modules).groupBy("competencies").map((value, key) => ({ id: key, name: key })).value()
                let new_level=_.chain(data.modules).groupBy("level").map((value, key) => ({ id: key, name: key })).value()
                setchoosen_jabatan({id:parseInt(params.jabatan),name:data.namePosition})
                settype_competence_list(new_type)
                setlevel_competence_list(new_level)
                setdetail(data)
                setmodules(data.modules)
                setloading(false)
            })
            .catch((error)=>{
                console.log(error)
                setloading(false)
            })
    }, [])
    const onChange=(event,value)=>{
        setchoosen_jabatan(value)
    }
    const searchJabatan=_.debounce(async (value,reason)=>{
        if(reason!=='reset'){
            setloading_search(true)
            fetch(URL+'/hcdp/listpositions/'+value)
                .then((res)=>res.json())
                .then((data)=>{
                    if(data.length>0){
                        let new_jabatan=[]
                        data.map((d)=>new_jabatan.push({...d,group:"Ketik dan pilih nama jabatan yang ingin ditinjau"}))
                        setlist_jabatan(new_jabatan)
                    }
                    setloading_search(false)
                })
                .catch((error)=>{
                    console.log(error)
                })
        }
    },1000)
    const searchChange=(value,reason)=>{
        setsearch(value)
        if(value!==''){
            searchJabatan(value,reason)
        }
    }
    const searchToggle=(value)=>{
        setsearch_module(value)
    }
    const renderModulesWithSearchFilter=()=>{
        let new_modules=modules
        if(choosen_type_compentence){
            new_modules=new_modules.filter((d)=>{
                return d.competencies===choosen_type_compentence.id
            })
        }
        if(choosen_level_compentence){
            new_modules=new_modules.filter((d)=>{
                return d.level===choosen_level_compentence.id
            })
        }
        if(search_module!==""){
            new_modules=new_modules.filter((d)=>{
                return d.modules.toLowerCase().includes(search_module.toLowerCase()) || d.competencies.toLowerCase().includes(search_module.toLowerCase())
            })
        }
        return new_modules
    }
    const onClickChoose=()=>{
        setloading(true)
        fetch(URL+'/hcdp/detailpositions/'+choosen_jabatan.id)
            .then((res)=>res.json())
            .then((data)=>{
                setdetail(data)
                setloading(false)
            })
            .catch((error)=>{
                console.log(error)
                setloading(false)
            })
    }
    const onClose=()=>{
        if(search===""){
            navigate("/", { replace: true });
        }
    }
    // const renderSplit=(string)=>{
    //     let split=string.split(' ')
    //     if (split[0]==='M') {
    //         return 'Manajerial'
    //     } else if(split[0]==='SK') {
    //         return 'Sosial Kultural'
            
    //     } else if(split[0]==='T'){
    //         return 'Teknis'

    //     }else{
    //         return 'Teknis'
    //     }
    // }
    return (
        <div>
        <div className='print:hidden flex justify-center items-center  lg:h-screen  '>
        <div className="py-6    w-screen  my-auto mx-auto  justify-center flex-col md:max-w-6xl md:flex-row  flex items-start    md:space-x-20">
            <div  className=" md:w-1/2 w-full  text-center">
                <div  className='px-6 md:px-0'>
                <h1 className="text-2xl font-bold">Human Capital Development Plan</h1>
                <br/>
                <div className="w-full">
                    <h3 className='text-sm font-bold text-left mb-3' >Jabatan</h3>
                    <AutoComplete
                        color='primary'
                        noOptionsText="Type jabatan name"
                        loading={loading_search}
                        disableClearable={true}
                        onChange={(event,value)=>onChange(event,value)}
                        options={list_jabatan}
                        getOptionLabel={(option) => option.name}
                        onInputChange={(event,e,r)=>searchChange(e,r)}
                        placeholder="Ketikkan nama jabatan anda"
                        value={choosen_jabatan}
                        groupBy={(option)=>option.group}
                        onClose={onClose}
                    />
                </div>
                <br/>
                <Button onClick={onClickChoose} disabled={!choosen_jabatan} variant="contained" color="primary" className="btn-remove-capital btn-rounded">
                    Lihat Rencana Pembelajaran
                </Button>
                <br/><br/>
                </div>
                <div  className="w-full shadow-lg">
                    <div  className="text-left p-6 md:p-3 flex flex-col  bg-primary bg-opacity-100  bg-motif1 bg-contain bg-no-repeat bg-right">
                        <p className="text-base text-blue-200">Kelompok Jabatan</p>
                        {detail&&detail.groupPositions.map((d,i)=>(
                            <p key={i} className="text-base text-white">{d.name}</p>

                        ))}
                        <p className="text-base text-blue-200">Jenjang pendidikan</p>
                        {detail&&detail.stages.map((d,i)=>(
                            <p key={i} className="text-base text-white">{d.name}</p>

                        ))}
                    </div>
                    <div className="py-6 px-6 md:py-3 md:px-3 text-left ">
                        <p>Kualifikasi bidang ilmu jabatan terkait</p>
                        <div className='flex flex-wrap  '> 
                            {detail&&detail.knowledges.map((d,i)=>(
                                <p key={i} className='px-1 py-0 rounded-sm bg-gray-200 mr-4 mt-4'>{d.name}</p>
                            ))}
                        </div>
                    </div>
                </div>
                <br/>
                <div className='w-full shadow-lg p-6 md:p-3 text-left'>
                    <h3 className='text-xl font-bold'>Filter</h3>
                    <br/>
                    <Select
                        color='primary'
                        loading={true}
                        disableClearable={false}
                        onChange={(event,value)=>setchoosen_type_compentence(value)}
                        options={type_competence_list}
                        getOptionLabel={(option) => option.name}
                        // onInputChange={(event,e)=>searchToggle(e)}
                        label={<>Tipe Kompetensi</>}
                        // placeholder="Ketikkan nama jabatan anda"
                    />
                    <br/>
                    <Select
                        color='primary'
                        loading={true}
                        disableClearable={false}
                        onChange={(event,value)=>setchoosen_level_compentence(value)}
                        options={level_competence_list}
                        getOptionLabel={(option) => option.name}
                        // onInputChange={(event,e)=>searchToggle(e)}
                        label={<>Level Kompetensi</>}
                        // placeholder="Ketikkan nama jabatan anda"
                    />
                    <br/>
                </div>
            </div>
            {loading?<div className="md:w-7/12 my-auto w-full text-left flex flex-col items-center justify-center ">
                <p className="text-gray-400">Sedang mengambil data</p>
                <br/>
                <CircularProgress data-test='loading-circular' style={{color:'#00a1ed'}}  size={25} />
            </div>
            :
            <div className="md:w-7/12 w-full text-center flex    ">
                
                <div  className='px-6 pt-6 md:pt-0 md:px-0  w-full text-left'>
                    <div className='md:pl-10'>
                    <h1 className="text-2xl font-bold">Daftar Pembelajaran</h1>
                    <br/>
                    <div className=' bg-secondary  shadow-lg flex py-5 px-3 justify-between'>
                        <div className='text-center w-28  flex flex-col justify-between p-2'>
                            <p className='font-semibold text-sm '>Total Modul</p>
                            <h1 className='text-3xl font-bold'>{detail?detail.totalModules:0}</h1>
                        </div>
                        <div className='text-center bg-white w-28  flex flex-col justify-between p-2  '>
                            <p className='font-semibold text-sm '>Manajerial</p>
                            <h1 className='text-3xl font-bold'>{detail?detail.totalManagerial:0}</h1>
                        </div>
                        <div className='text-center bg-white w-28  flex flex-col justify-between p-2 '>
                            <p className='font-semibold text-sm '>Sosial Kultural</p>
                            <h1 className='text-3xl font-bold'>{detail?detail.totalSocialCulture:0}</h1>
                        </div>
                        <div className='text-center bg-white w-28  flex flex-col justify-between p-2 '>
                            <p className='font-semibold text-sm '>Teknis</p>
                            <h1 className='text-3xl font-bold'>{detail?detail.totalTechnical:0}</h1>
                        </div>
                    </div>
                    </div>
                    <br/>
                    <div className='w-full  '>
                        <div className='flex w-full justify-between md:px-10'>
                            <div className='w-2/3'>
                            <Search
                                color='primary'
                                searchToggle={(value)=>searchToggle(value)}
                                width="100%"
                                // label={<>Nama Klien</>}
                                placeholder="Cari Pembelajaran"
                            />
                            </div>
                            <Button onClick={()=>window.print()} variant="contained" color="primary" className="btn-remove-capital btn-rounded">
                                Download
                            </Button>
                        </div>
                        <br/>
                        <div className=' max-h-96 overflow-y-auto  md:px-10'>
                            {renderModulesWithSearchFilter().map((d,i)=>(
                                <div key={i} className='shadow-lg mb-6 w-full '>
                                    <div  className={`text-left p-6  ${i%2!==0?'bg-fortier bg-motif3':'bg-tersier bg-motif2'} bg-opacity-100   bg-contain bg-no-repeat bg-right`}>
                                        <div className='flex  justify-between w-10/12 md:w-8/12'>
                                            <p className="text-sm font-display w-6/12  text-white">{d.competencies} | Level : {d.level}</p>
                                        </div>
                                        
                                    </div>
                                    <div className='text-left p-6 bg-white'>
                                        <p className='text-sm font-display '>Modul</p>
                                        <p className='text-sm font-display '>{d.modules}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            }
            
        </div>
        </div>
        {detail&&<div style={{WebkitPrintColorAdjust:'exact',colorAdjust:'exact'}} className='relative w-2/3  mx-auto print:w-full hidden  print:block'>
                
            <table>
                <thead >
                    <div  className=' h-[86px] bg-primary px-[40px] flex items-center bg-opacity-100 bg-motif1 bg-contain bg-no-repeat bg-right'>
                        <h1 className='text-[24px] text-white font-bold'>Human Capital Development Plan</h1>
                    </div> 
                </thead>
                <tbody>
                    <div className='p-[40px] '>
                        <h3 className='text-[16px] font-bold text-left mb-3' >Jabatan</h3>
                        <h1 className='text-[24px] text-[#00A1ED] font-semibold mb-4'>{choosen_jabatan.name}</h1>
                        <h3 className='text-[16px] font-bold text-left mb-3' >Kelompok Jabatan</h3>
                        {detail&&detail.groupPositions.map((d,i)=>(
                            <h2 key={i} className='text-[20px] mb-4'>{d.name}</h2>
                        ))}
                        <h3 className='text-[16px] font-bold text-left mb-3' >Jenjang Pendidikan</h3>
                        {detail&&detail.stages.map((d,i)=>(
                            <h2 key={i} className='text-[20px] mb-4'>{d.name}</h2>
                        ))}
                        <br/>
                        <h3 className='text-[16px] font-bold text-left mb-3' >Kualifikasi bidang ilmu jabatan terkait</h3>
                        <div  className='flex flex-wrap  '> 
                            {detail&&detail.knowledges.map((d,i)=>(
                                <p key={i}  className='px-1 py-0 rounded-sm bg-gray-200 mr-4 mt-4'>{d.name}</p>
                            ))}
                            
                        </div>
                        <br/>
                        <h3 className='text-[16px] font-bold text-left mb-3' >Daftar Pembelajaran</h3>
                        
                        <div  className=' bg-secondary  shadow-lg flex py-5 px-3 justify-between mb-6'>
                            <div className='text-center w-[150px]  flex flex-col justify-between p-2'>
                                <p className='font-semibold text-sm '>Total Modul</p>
                                <h1 className='text-3xl font-bold'>{detail?detail.totalModules:0}</h1>
                            </div>
                            <div className='text-center bg-white w-[150px]  flex flex-col justify-between p-2  '>
                                <p className='font-semibold text-sm '>Manajerial</p>
                                <h1 className='text-3xl font-bold'>{detail?detail.totalManagerial:0}</h1>
                            </div>
                            <div className='text-center bg-white w-[150px]  flex flex-col justify-between p-2 '>
                                <p className='font-semibold text-sm '>Sosial Kultural</p>
                                <h1 className='text-3xl font-bold'>{detail?detail.totalSocialCulture:0}</h1>
                            </div>
                            <div className='text-center bg-white w-[150px]  flex flex-col justify-between p-2 '>
                                <p className='font-semibold text-sm '>Teknis</p>
                                <h1 className='text-3xl font-bold'>{detail?detail.totalTechnical:0}</h1>
                            </div>
                        </div>
                        {renderModulesWithSearchFilter().map((d,i)=>(
                            <div key={i} className={`shadow-lg  w-full pt-6`} style={{pageBreakInside:'avoid '}}>
                                <div  className={`text-left p-6  ${i%2!==0?'bg-fortier bg-motif3':'bg-tersier bg-motif2'} bg-opacity-100   bg-contain bg-no-repeat bg-right`}>
                                    <div className='flex  justify-between w-10/12 md:w-8/12'>
                                        <p className="text-sm font-display w-6/12  text-white">{d.competencies} | Level : {d.level}</p>
                                    </div>
                                    
                                </div>
                                <div className='text-left p-6 bg-white'>
                                    <p className='text-sm font-display '>Modul</p>
                                    <p className='text-sm font-display '>{d.modules}</p>
                                </div>
                            </div>
                        ))}
                        </div>
                </tbody>
            </table>         
            
        </div>}
        </div>
    )
}

import {
    BrowserRouter as Router,
    Routes,
    Route,
  } from "react-router-dom";
import Landing from "../features/landing";
import Result from "../features/result";
const IndexRouter=()=>{
    return(
        <Router>
            <Routes>
                <Route path="/" element={<Landing/>}/>
                <Route path="/:jabatan" element={<Result/>}/>
            </Routes>
        </Router>
    )
}
export default IndexRouter
import React,{useEffect, useState} from 'react'
import LandingBg from '../../assets/images/landing.png'
import AutoComplete from '../../components/AutoCompleteSelect'
import  Button  from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom';
import { URL } from '../../app/base_url';
import _ from 'lodash'
export default function Landing() {
    const [list_jabatan, setlist_jabatan] = useState([])
    const [choosen_jabatan, setchoosen_jabatan] = useState(null)
    const [search, setsearch] = useState('')
    const [loading, setloading] = useState(false)
    const [textselect, settextselect] = useState('')
    useEffect(() => {
        fetch(URL+'/hcdp/listpositions')
            .then((res)=>res.json())
            .then((data)=>{
                let new_jabatan=[]
                data.map((d)=>new_jabatan.push({...d,group:"Ketik dan pilih nama jabatan yang ingin ditinjau"}))
                setlist_jabatan(new_jabatan)
            })
    }, [])
    const searchToggle=_.debounce(async (event,value,reason)=>{
        setsearch(value)
        if(reason!=='reset'){
            setloading(true)
            fetch(URL+'/hcdp/listpositions/'+value)
                .then((res)=>res.json())
                .then((data)=>{
                    if(data.length>0){
                        let new_jabatan=[]
                        data.map((d)=>new_jabatan.push({...d,group:"Ketik dan pilih nama jabatan yang ingin ditinjau"}))
                        setlist_jabatan(new_jabatan)
                    }else{
                        settextselect('No Options.')
                    }
                    setloading(false)

                })
                .catch((error)=>{
                    console.log(error)
                })
        }
        
    },1000)
    const onClickChoose=()=>{

    }
    // console.log(`choosen_jabatan`, choosen_jabatan)
    return (
        <div className="  my-auto h-screen justify-center flex-col md:max-w-6xl md:flex-row md:h-screen flex items-center mx-auto space-y-10  md:space-x-20">
            <div  className=" md:w-1/2 w-full px-6 md:px-0 text-center">
                <h1 className="text-2xl font-bold">Human Capital Development Plan</h1>
                <br/>
                <div className="w-full">
                    <AutoComplete
                        color='primary'
                        // noOptionsText="Type jabatan name"
                        loading={loading}
                        disableClearable={true}
                        onChange={(event,value)=>setchoosen_jabatan(value)}
                        options={list_jabatan}
                        noOptionsText={textselect}
                        getOptionLabel={(option) => option.name}
                        groupBy={(option)=>option.group}
                        onInputChange={(event,e,reason)=>searchToggle(event,e,reason)}
                        // label={<>Nama Klien</>}
                        placeholder="Ketikkan nama jabatan anda"
                    />
                </div>
                <br/>
                <Button  disabled={!choosen_jabatan} variant="contained" color="primary" className="btn-remove-capital btn-rounded">
                    <Link to={`/${choosen_jabatan&&choosen_jabatan.id}`}>Lihat Rencana Pembelajaran</Link>
                    
                </Button>
                
            </div>
            <div className="md:w-1/2 w-full text-left flex flex-col items-center justify-center ">
                <img src={LandingBg} alt="bg" className="md:w-5/6 w-5/6"/>
                {/* <p className="text-gray-400">Sedang mengambil data</p>
                <br/>
                <CircularProgress data-test='loading-circular' style={{color:'#00a1ed'}}  size={25} /> */}
                
            </div>
        </div>
    )
}

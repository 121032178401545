import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@mui/styles';
import SearchImg from '../../assets/icons/Search.png'
import { InputAdornment } from '@mui/material';
const useStyles = makeStyles(theme => ({
    textField: {
      [`& fieldset`]: {
        borderRadius: 15,

      },
      width:'100%',
      marginBottom:15,
  },
  noBorder: {
    border: "none",
  },
  
}));

export default function Index(props) {
    const classes=useStyles()
    return (
            <Autocomplete
                {...props}
                size='small'
                options={props.options}
                getOptionLabel={props.getOptionLabel}
                onInputChange={props.onInputChange}
                loading={props.loading}
                loadingText='Loading...'
                onChange={props.onChange}
                freeSolo
                renderInput={(params) => 
                
                <TextField  className={classes.textField} 
                    {...params} 
                    label={props.label} 
                    variant="outlined" 
                    placeholder={props.placeholder}
                    
                    // propsTextField={props.propsTextField}
                    InputProps={{
                        ...params.InputProps,
                        type: 'search',
                        startAdornment:(
                            <InputAdornment position="start">
                                <img src={SearchImg} alt="search" className="w-4"/>
                            </InputAdornment>
                        )
                    }}
                />
            }
            />
    )
}
import IndexRouter from './app/routes';
import { createTheme, ThemeProvider } from '@mui/material/styles';
const themeButton = createTheme({ 
  palette: { 
      primary: {
        main:'#00a1ed',
        contrastText: '#FFFFFF',

      },
      secondary: {
          main:'#4cc614',
          contrastText: '#FFFFFF',

      }
  } 
})
function App() {
  return (
      <div >
        <ThemeProvider theme={themeButton}>
        <IndexRouter/>

        </ThemeProvider>
      </div>
  );
}

export default App;
